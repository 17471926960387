import {
    Show,
    List,
    Edit,
    Create,
    BooleanField,
    BooleanInput,
    ArrayField,
    ChipField,
    SingleFieldList,
    SimpleList,
    AutocompleteArrayInput,
    SearchInput,
    useNotify,
    useRecordContext,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField, SimpleForm, TextInput, EditButton, ReferenceArrayInput, SelectInput} from "react-admin";
import { ShowButton } from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {ClipLoader} from "react-spinners";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import dataProvider from "../../../Providers/dataProvider";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";

export const UserUserList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title="Users" filters={postFilters} resource="user/users" hasCreate={true} exporter={false}>
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => record.email}
                    tertiaryText={record => record.email}
                />
            ) : (
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <TextField source="name" />
                    <TextField source="email" />
                    <TextField source="branch.name" label="Branch" />
                    <BooleanField source="is_active" label="Active" />
                    <DateField showTime={true} source="created_at" label="Created" />
                    <DateField showTime={true} source="updated_at" label="Updated"  />
                </Datagrid>
            )}
        </List>
    );
}

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
    <SearchInput placeholder="E-Mail" source="email" name="email" alwaysOn={true} key={Math.random()} />,
];

export const UserUserShow = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Show title={<PageTitle />} actions={<ShowActions />}>
            {isSmall ? (
                <SimpleShowLayout>
                    <TextField source="name"/>
                    <TextField source="email"/>
                    <TextField source="branch.name" name="Branch" />
                    <ArrayField source="permissions">
                        <SingleFieldList linkType={false}>
                            <ChipField source="name" size="small" />
                        </SingleFieldList>
                    </ArrayField>
                    <BooleanField source="is_active" />
                    <DateField showTime={true} source="created_at" label="Created" />
                    <DateField showTime={true} source="updated_at" label="Updated" />
                </SimpleShowLayout>
            ) : (
                <Grid container>
                    <Grid item xs={4}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">User</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="name"/>
                            <TextField source="email"/>
                            <TextField source="branch.name" name="Branch" />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Resources</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <ArrayField source="permissions">
                                <SingleFieldList linkType={false}>
                                    <ChipField source="name" size="small" />
                                </SingleFieldList>
                            </ArrayField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Status</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <BooleanField source="is_active" />
                            <DateField showTime={true} source="created_at" label="Created" />
                            <DateField showTime={true} source="updated_at" label="Updated" />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            )}
        </Show>
    );
}

export const UserUserCreate = () => {
    return (
        <Create redirect="show">
            <SimpleForm>
                <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
                <TextInput source="email" required={true} name={"email"} style={{ width : '300px' }} />
                <ReferenceArrayInput name="Branches" source="branch_id" reference="user/branches" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <SelectInput optionText="name" required={true} style={{ width : '300px' }} />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export const UserUserEdit = () => {
    return (
        <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show" >
            <SimpleForm>
                <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
                <TextInput source="email" required={true} name={"email"} style={{ width : '300px' }} />
                <ReferenceArrayInput name="Branch" source="branch_id" reference="user/branches" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <SelectInput optionText="name" style={{ width : '300px' }} />
                </ReferenceArrayInput>
                <ReferenceArrayInput name="Permissions" source="permissions" reference="user/permissions"  perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <AutocompleteArrayInput
                        name="permissions"
                        optionText="name"
                        format={value => value.map(value => value.id)}
                        parse={value => value.map(value => {return { id: value}})}
                        style={{ width : '300px' }}
                    />
                </ReferenceArrayInput>
                <BooleanInput source="is_active" label="Active" options={{}}/>
            </SimpleForm>
        </Edit>
    );
};

const SendWelcomeEmailButton = () => {
    const notify = useNotify();
    let [color] = useState("#1976d2");
    const record = useRecordContext();
    const port = window.location.port;
    const portString = port ? ':' + port : '';
    const link = window.location.protocol + '//' + window.location.hostname + portString + '/';
    const { mutate, isSuccess, isLoading, isError, error } = useMutation(
        () => dataProvider.sendWelcomeEmail('user', record.id, link)
    );

    if (isLoading) {
        return <ClipLoader color={color}/>
    }

    if (isError) {
        return <p>ERROR: {error}</p>
    }

    if (isSuccess) {
        notify('Welcome Email sent', { type: "success"});
    }

    return (
        <Button
            style={{marginBottom: -3}}
            size="small"
            label="Send Welcome Email"
            startIcon={<ForwardToInboxIcon />}
            onClick={() => mutate()}
            disabled={isLoading}
        >Send Welcome Email</Button>
    );
}

const ShowActions = () => (
    <TopToolbar backButton={true}>
        <SendWelcomeEmailButton />
        <EditButton />
    </TopToolbar>
);

const EditActions = () => (
    <TopToolbar>
        <ShowButton />
    </TopToolbar>
);